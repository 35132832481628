







import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    footerLayout1: () => import('@/components/business/pc/footer/InsFooterLayout1.vue')
  }
})
export default class InsFooter extends Vue {
  layout: string = 'footerLayout1';
  htmlHeight = document.body.offsetHeight;
  vh = window.innerHeight;
  updated () {
  }
  @Watch('$route', { deep: true })
  onRouteChange () {
  }
}
